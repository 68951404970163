<template>
  <Day day="03.12.2024">
    <div slot="page-content">
      <h3>Luke 3🎄</h3>
      <!-- <p>
        Feel free to add whatever kind of markup, images, videos or custom JS logic as you like!
      </p> -->
      <p class="caption-img">
        <img
          src="../../images/3.jpg"
          alt=""
        >
      </p>
      <p>
        Dagens sitat:
      </p>
      <p>
        "En hær av sauer ledet av en løve vil slå en hær av løver ledet av en sau."
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day03',
  components: {
    Day
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  
</style>
