<template>
  <div>
    <slot name="back">
      <back-to-calendar :year="year"/>
    </slot>
    <h1 class="headline">{{ date.format('DD. MMMM') }}</h1>
    <main class="page-content" v-if="isAllowedToView">
      <slot name="page-content" class="page-content"></slot>
    </main>
    <main v-else>
      <div class="page-content">

        <p class="caption-img">
        <img
          src="../images/stopit3.png"
          alt=""
        >
        </p>
        <h2>Åååå nei du... ✋😂</h2>
        <h2>Ikke juks... </h2>
        <p style="text-align:center; font-style: italic;">
          "Du må nok vente litt!!!""
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import Moment from 'moment'
import BackToCalendar from "@/components/BackToCalendar";
require('dotenv').config();

export default {
  name: 'Day',
  props: ['day'],
  components: {
    BackToCalendar
  },
  computed: {
    date: function () {
      let mom = new Moment(this.day, 'DD.MM.YYYY');
      mom.locale('nb');
      return mom;
    },
    now: function () {
      let mom = new Moment();
      mom.locale('nb');
      return mom;
    },
    isAllowedToView: function () {
      return this.date <= this.now || process.env.VUE_APP_NODE_ENV === 'development';
    },
    year: function () {
      return this.date.format('YYYY')
    }
  }
}
</script>

<style lang="scss">
.page-content {
  width: 50%;
  margin: 0 auto;
  flex-flow: column;
  text-align: center;

  background: rgba(#000, 0.3);
  padding: 25px;

  @media screen and (max-width: 900px) {
    width: 75%;
  }

  @media screen and (max-width: 400px) {
    width: 90%;
  }
}

.page-content video {
  max-width: 600px;
}

.small-video {
  display: flex;
  justify-content: center;
}

img.thumbnail {
  max-height: 100px;
  width: auto;
}

.big {
  max-width: 600px;
  width: 100%;
}

p.center {
  text-align: center;
}

h1.headline {
  margin-top: 20px;
  margin-bottom: 20px;
}

img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>
