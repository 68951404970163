<template>
  <div>
    <div class="background">
      <video 
        v-if="isAllowedToView()"
        autoplay 
        loop 
        id="video-background" 
        muted 
        playsinline>
        <source :src="video.src" :type="video.type" />
      </video>
    </div>
    <Day day="24.12.2024">
      <div slot="page-content">
        <h3>💝Luke 24💝</h3>
        <p class="caption-img">
          <img
            src="../../images/24.jpg"
            alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          Hvorfor er det så viktig å finne sin rette hylle - helst så fort som mulig?
        </p>
        <p>
          Hva i all verden skal vi sitte der for - og dingle med bena resten av livet?
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio24">
      <source src="../../assets/24.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day24',
  methods: {
    isAllowedToView: function () {
      return Utils.isDayAllowed('24');
    },
  },
  computed: {
    video: function () {
      return {
        src: require('../../assets/octagon.mp4'),
        type: 'video/mp4'
      }
    }
  },  
  components: {
    Day
  },
  beforeRouteLeave(to, from, next) {

    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio24");
      audio.pause();
    }
    next();
  }
}
</script>

<style scoped>

  #video-background {
    /*  making the video fullscreen  */
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
  }

  h3 {
    font-family: 'Handlee', cursive;
    font-size: 60px;
    color: rgb(240, 172, 172);
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>