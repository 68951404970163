<template>
  <div id="app">
    <div class="background">
      <video 
        autoplay 
        loop 
        id="video-background" 
        muted 
        playsinline>
        <source :src="video.src" :type="video.type" />
      </video>
    </div>
    <div class="content">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
//  import Moment from 'moment'

export default {
  name: 'app',
  data () {
    return {
      sources: [
        // {
        //   src: require('./assets/octagon.mp4'),
        //   type: 'video/mp4'
        // },
        {
          src: require('./assets/particles.mp4'),
          type: 'video/mp4'
        },
        // {
        //   src: require('./assets/snowflakes.mp4'),
        //   type: 'video/mp4'
        // },
      ]
    }
  },
  computed: {
    video: function () {
      return this.sources[0];
      // let day = (new Moment()).format('d')
      // const idx = day % 2 == 0 ? 1 : 0;
      // return this.sources[idx];

      // return this.sources[Math.floor(Math.random() * 3)]
      // random on every page load:
      // return this.sources[day % this.sources.length];
    }
  },
  mounted() {
    document.title = "JuleKAlender 2024";
  },
};

</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Handlee|Montserrat:300,400');

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    background: #222;
    margin: 0;
    width: 100%;
    max-width: 100%;

    font-size: 100%;
  }

  a {
    color: white;
    outline: none;
  }

  h1 {
    font-size: 6rem;
    text-shadow: 0 2px 8px #666;
    margin: 50px;

  @media screen and (max-width: 900px) {
    font-size: 3rem;
    margin: 30px;
  }

  @media screen and (max-width: 400px) {
    font-size: 2rem;
    margin: 25px;
  }
  }

  h1, h2 {
    font-family: 'Handlee', cursive;
    text-align: center;
  }

  #video-background {
    /*  making the video fullscreen  */
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
  }

  .fade-enter-active, 
  .fade-leave-active {
    transition: opacity 1s;
  }

  .fade-enter, 
  .fade-leave-to {
    opacity: 0; 
  }
</style>
