<template>
  <div>
    <Day day="18.12.2024">
      <div slot="page-content">
        <h3>Luke 18💻</h3>
        <!-- <p>
          Feel free to add whatever kind of markup, images, videos or custom JS logic as you like!
        </p> -->
        <p class="caption-img">
          <img
            src="../../images/18.jpg"
            alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "Gud ga menn en penis og en hjerne, men uheldigvis ikke nok blodomløp til å operere begge deler samtidig."
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio18">
      <source src="../../assets/18.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day18',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      return Utils.isDayAllowed('24');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio18");
      audio.pause();
    }
    next();
  }

}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }
</style>