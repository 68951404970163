<template>
  <div>
    <Day day="11.12.2024">
      <div slot="page-content">
        <h3>🐦Luke 11🐦</h3>
        <!-- <p>
          Feel free to add whatever kind of markup, images, videos or custom JS logic as you like!
        </p> -->
        <p class="caption-img">
          <img
            src="../../images/11.jpg"
            alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "I tider som dette er det viktig å huske at det alltid har vært tider som dette."
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio11">
      <source src="../../assets/11.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day11',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      return Utils.isDayAllowed('11');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio11");
      audio.pause();
    }
    next();
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>