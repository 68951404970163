<template>
  <div>
    <Day day="19.12.2024">
        <div slot="page-content">
          <h3>Luke 19👌</h3>
          <!-- <p>
            Feel free to add whatever kind of markup, images, videos or custom JS logic as you like!
          </p> -->
          <p class="caption-img">
            <img
              src="../../images/19.jpg"
              alt=""
            >
          </p>
          <p>
            Dagens sitat:
          </p>
          <p>
            "Man må mene det man sier, men man må ikke alltid si det man mener."
          </p>
        </div>
      </Day>
      <audio v-if="isAllowedToView()" autoplay id="myaudio19">
        <source src="../../assets/19.mp3">
      </audio>    
  </div>
</template>

<script>
import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day19',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      // return true;
      return Utils.isDayAllowed('19');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio19");
      audio.pause();
    }
    next();
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  


</style>