<template>
  <div>
    <Day day="07.12.2024">
      <div slot="page-content">
        <h3>Luke 7🤗</h3>
        <!-- <p>
          Feel free to add whatever kind of markup, images, videos or custom JS logic as you like!
        </p> -->
        <p class="caption-img">
          <img
            src="../../images/7.jpg"
            alt=""
          >
        </p>    
        <p>
          Dagens sitat:
        </p>
        <p>
          "Noen mennesker vil bare elske deg så lenge du passer inn i deres boks. Ikke vær redd for å skuffe dem."
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio7">
      <source src="../../assets/7.mp3">
    </audio>    

  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day07',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      return Utils.isDayAllowed('7');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio7");
      audio.pause();
    }
    next();
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>