class Utils {
  static fadeOutMusic(audio, next) {
    var vol = 0.7;
    var interval = 150; 

    var fadeout = setInterval(
      function() {
        if (vol > 0) {
          vol -= 0.05;

          if (vol < 0) {
            vol = 0;
          }
          audio.volume = vol;
        }
        else {
          clearInterval(fadeout);
          next();
        }
      }, interval);
    }

    static isDayAllowed(day) {
      require('dotenv').config();
      const Moment = require('moment');
      let date = new Moment(day, 'DD.MM.YYYY');
      let now = new Moment();
      return now >= date || process.env.VUE_APP_NODE_ENV === 'development';
    }
}

module.exports = Utils;