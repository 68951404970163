<template>
  <Day day="01.12.2024">
    <div slot="page-content">
      <h3>Luke 1👩‍🎓</h3>      
      <p class="caption-img">
        <img
          src="../../images/1.jpg"
          alt=""
        >
      </p>
      <p>
        Dagens sitat:
      </p>
      <p>
        "Man slutter ikke å leke bare fordi man blir gammel."
      </p>
      <p>
        Man blir gammel fordi man slutter å leke!"
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day01',
  components: {
    Day
  }
}
</script>

<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  
</style>
